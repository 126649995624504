import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Vinur() {
    return (
        <body>
            <Header />
            <main>
                <h1>Vinur</h1>
            </main>
            <Footer />
        </body>
    );
}
