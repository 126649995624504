import styled from "styled-components";
import { Link } from "react-router-dom";
import Hero from "./Hero";
import Logo from "../Assets/images/logo.png";
import { useState } from "react";
import { faBars, faGear, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Header() {
    const [menuState, setMenuState] = useState(false);
    return (
        <StyledHeader menuStateShow={menuState}>
            <nav>
                <div className="logo">
                    <Link to="/">
                        <img src={Logo} alt="logo AVanderhoef" />
                    </Link>
                </div>
                <ul>
                    {/* <li>
                        <Link to="/">Forside</Link>
                    </li> */}
                    <li>
                        <Link to="/racen">Om Racen</Link>
                    </li>
                    <li>
                        <Link to="/bessi">Geislandi Bessi</Link>
                    </li>
                    <li>
                        <Link to="/vinur">Ganti Vinur</Link>
                    </li>
                    <li>
                        <Link to="/skúli">Skúli</Link>
                    </li>
                    <li>
                        <Link to="/fylkir">Vinfengis Fylkir</Link>
                    </li>
                    <li>
                        <Link to="/galleri">Galleri</Link>
                    </li>
                    <li>
                        <Link to="/kontakt">Kontakt</Link>
                    </li>
                </ul>
                <span className="burgerMenu" onClick={() => setMenuState((prevState) => !prevState)}>
                    {menuState ? <FontAwesomeIcon icon={faX} /> : <FontAwesomeIcon icon={faBars} />}
                </span>
            </nav>
            <Hero />
        </StyledHeader>
    );
}

const StyledHeader = styled.header`
    nav {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        .logo {
            img {
                width: 60px;
                height: 60px;
            }
        }
        .burgerMenu {
            display: none;
            padding: 0.8rem 1rem;
            font-size: 1.5rem;
            transition: ease-in-out 0.1s;
            &:hover {
                color: #c7b198;
                font-size: 1.7rem;
            }
            @media only screen and (max-width: 899px) {
                display: block;
            }
        }
        ul {
            height: 100%;
            width: 100%;
            display: ${(props) => (props.menuStateShow ? "flex" : "none")};
            @media only screen and (min-width: 900px) {
                display: flex;
            }
            justify-content: space-around;
            align-items: center;
            li {
                list-style: none;
                a {
                    text-decoration: none;
                    color: #000000;
                    font-weight: 600;
                    &:hover {
                        color: #c7b198;
                    }
                }
            }
        }

        @media screen and (max-width: 899px) {
            position: relative;
            justify-content: space-between;

            ul {
                font-size: 1.5rem;
                position: ${(props) => (props.menuStateShow ? "absolute" : "unset")};
                top: 60px;
                left: 0;
                background: #fff;
                height: calc(100dvh - 60px);
                width: 100vw;
                flex-direction: column;
                justify-content: unset;
                gap: 2rem;
                padding: 2rem 0;
            }
        }
    }
    .hero {
        max-width: 900px;
        margin: auto;
        img {
            width: 100%;
            aspect-ratio: 22/8;
            object-fit: cover;
            object-position: 0 -70px;
            @media screen and (max-width: 899px) {
                object-position: 0 -35px;
            }
        }
    }
`;
