import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import AboutRace from "../Pages/AboutRace";
import NotFound from "../Pages/NotFound";
import Bessi from "../Pages/Bessi";
import Vinur from "../Pages/Vinur";
import Skuli from "../Pages/Skuli";
import Fylkir from "../Pages/Fylkir";
import Contact from "../Pages/Contact";

export default function SiteRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/racen" element={<AboutRace />} />
                <Route path="/kontakt" element={<Contact />} />
                <Route path="/bessi" element={<Bessi />} />
                <Route path="/vinur" element={<Vinur />} />
                <Route path="/skúli" element={<Skuli />} />
                <Route path="/skuli" element={<Skuli />} />
                <Route path="/fylkir" element={<Fylkir />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}
