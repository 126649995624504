import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledFooter = styled.footer`
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    background-color: #00000015;
    flex-wrap: wrap;
    padding: 0 1rem;
    p {
        margin: 0px;
    }
`;
const MadeBy = styled.div`
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    color: #efefef;
    font-weight: 500;
    a {
        color: #efefef;
        font-weight: 600;
    }
`;
const thisYear = new Date().getFullYear();

export default function Footer() {
    return (
        <>
            <StyledFooter>
                <p>geislandi-bessi.dk / vanderhoef.dk 2011-{thisYear} </p>
                <Link to="TOS">Terms Of Use</Link>
                <Link to="PP">Privacy Policy</Link>
            </StyledFooter>
            <MadeBy>
                <p>
                    Made By: <a href="https://jannick.ninja">Jannick Vanderhoef</a>
                </p>
            </MadeBy>
        </>
    );
}
