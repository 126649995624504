import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Contact() {
    return (
        <body>
            <Header />
            <main>
                <h1>Kontakt</h1>
                <p>Skriv til Anne Marie Vanderhoef her</p>
                <p>
                    Email: <a href="mailto:avanderhoef@hotmail.com">avanderhoef@hotmail.com</a>
                </p>
            </main>
            <Footer />
        </body>
    );
}
