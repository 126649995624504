import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function FamilyTree({ treeOff }) {
    const [showInfo, setShowInfo] = useState(false);
    return (
        <StyledFamilyTree showInfo={showInfo}>
            <h2>{treeOff}'s Familie træ</h2>
            <section>
                <div className="TreeProfile" key="1" onClick={() => setShowInfo((prevState) => !prevState)}>
                    <img
                        src="https://lh3.googleusercontent.com/pw/AP1GczPHDiMwVb5EUblF__qMxQT7yX_DTV9mB4xwg7gElQ3D_0SQo-w5Myx_IK3XtnRnbqrKz-3tLvxwAqIhKwGGJWryKL1NqMxtpuevbkW87FgOyr8PlMSKyFUny2GGkEbbwGCKHcamnRmzwwauWEM-UARG=w959-h1279-s-no?authuser=02"
                        alt="piture of skúli"
                    />
                    <div className="ProfileInfo">
                        <h3>{treeOff}</h3>
                        <div>
                            <label>køn:</label>
                            <p>Han</p>
                        </div>
                        <div>
                            <label>Født:</label>
                            <p>27-09-2010</p>
                        </div>
                        <div>
                            <label>Stambog nr.</label>
                            <p>DK17994/2010</p>
                        </div>
                        <div>
                            <label>Side:</label>
                            <Link to="/skúli">Vanderhoef.dk</Link>
                        </div>
                    </div>
                </div>
                <div className="TreeProfile" key="1" onClick={() => setShowInfo((prevState) => !prevState)}>
                    <img
                        src="https://lh3.googleusercontent.com/pw/AP1GczN0j3RIxb_3NzwI0S7mZ9wCoZR7iOyIORB2TllFyDserzJtRUk35LPVlK9h3jpyfDppeBFGuNZcAbIsZ9rDnkoYYCr9qvlqBlD8mO8LIHYQa1qCO-MUxHn5qRvtwHCKMi4suURc44dZ0s5ygKG15W3k=w440-h490-s-no?authuser=0"
                        alt="piture of Geislandi Bessi"
                    />
                    <div className="ProfileInfo">
                        <h3>Geislandi Bessi</h3>
                        <div>
                            <label>køn:</label>
                            <p>Han</p>
                        </div>
                        <div>
                            <label>Født:</label>
                            <p>06-07-2006</p>
                        </div>
                        <div>
                            <label>Stambog nr.</label>
                            <p>DKK15292/2003</p>
                        </div>
                        <div>
                            <label>Side:</label>
                            <Link to="/bessi">Vanderhoef.dk</Link>
                        </div>
                    </div>
                </div>
                <div className="TreeProfile" key="1" onClick={() => setShowInfo((prevState) => !prevState)}>
                    <img
                        src="https://lh3.googleusercontent.com/pw/AP1GczNYMJGr1xumO2_xpKg3UEM-NLecxLSwuQ2KULvG2MDrdhtPjQ8u9d7EM8KpdSnHevUQup6-bmOBQ9AwcoYWhGUy8Z7tmjnq9jyP7PcUGJJXQ2OGxWYGlIGum6hniegv0UKehv4lJPd8NJp5KPrgKgpW=w628-h471-s-no?authuser=0"
                        alt="piture of Gladur Bryja"
                    />
                    <div className="ProfileInfo">
                        <h3>Gladur Bryja</h3>
                        <div>
                            <label>køn:</label>
                            <p>Hun</p>
                        </div>
                        <div>
                            <label>Født:</label>
                            <p>18-07-2003</p>
                        </div>
                        <div>
                            <label>Stambog nr.</label>
                            <p>14441/2006</p>
                        </div>
                    </div>
                </div>
            </section>
        </StyledFamilyTree>
    );
}

const StyledFamilyTree = styled.div`
    h2 {
        color: #c7b198;
    }
    .TreeProfile {
        display: flex;
        width: ${(props) => (props.showInfo ? "100%" : "170px")};
        max-width: 450px;
        border: 5px solid #c7b198;
        position: relative;
        margin: 0 0 1rem 0;
        img {
            width: 160px;
            margin: 0;
            padding: 0;
            display: block;
            aspect-ratio: 4/4;
            object-fit: cover;
        }
        .ProfileInfo {
            padding: 0.4rem 1rem;

            width: 100%;
            display: ${(props) => (props.showInfo ? "unset" : "none")};

            div {
                display: flex;
                justify-content: space-between;
                p {
                    margin: 0;
                }
            }
        }
    }
`;
