import Header from "../components/Header";
import Footer from "../components/Footer";
import styled from "styled-components";
import FamilyTree from "../components/FamilyTree";

export default function Skuli() {
    return (
        <body>
            <Header />
            <StyledMain>
                <h1>
                    Skúli <small>Maduli</small>
                </h1>
                <FamilyTree treeOff="Skúli" />
            </StyledMain>
            <Footer />
        </body>
    );
}

const StyledMain = styled.main`
    width: 100%;
    max-width: 900px;
    margin: 1.2rem auto;
    padding: 0rem;
    small {
        font-size: 1rem;
    }

    @media only screen and (max-width: 900px) {
        padding: 0 1rem;
    }
`;
