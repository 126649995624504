import Header from "../components/Header";
import Footer from "../components/Footer";
import racen from "../Assets/images/racen.jpg";
import styled from "styled-components";
import islandshunden from "../Assets/images/islandshunden.png";

const TextField = styled.section`
    max-width: 900px;
    display: flex;
    margin: auto;
    gap: 1.5rem;
    p {
        font-weight: 500;
        margin: 0 0 1rem 0;
        &:last-child {
            margin: 0;
        }
    }
    a {
        display: flex;
        gap: 1rem;
        height: 25px;
        color: #c7b198;
        &:hover {
            color: #000000;
        }
    }
    @media screen and (max-width: 899px) {
        flex-direction: column-reverse;
    }
`;

export default function AboutRace() {
    return (
        <body>
            <Header />
            <main>
                <h1>Om Racen</h1>
                <TextField>
                    <article style={{ width: "300px" }}>
                        <img src={racen} alt="et billed af bessi " />
                        <p>
                            Læs mere om den Islanske Fårehund <br /> på klubbens hjemmeside.
                        </p>
                        <a href="https://www.islandshunden.dk" target="_blank" rel="noreferrer">
                            <img src={islandshunden} alt="" />
                            islandshunden.dk
                        </a>
                    </article>
                    <article>
                        <p>Den Islandske Fårehund er en glad og livlig hund, der knytter sig tæt til familien og helst følger den overalt.</p>
                        <p>
                            Den er venlig overfor alle og en stor børneven. Dens livlige væsen kommer mest til udtryk udendørs, da den hurtigt falder til ro og lægger sig til hvile ved ens fødder, når
                            familien er i ro.
                        </p>
                        <p>Den er meget lærenem og samarbejdsvillig, og har samtidig et selvstændigt sindelag.</p>
                        <p>Den kræver en konsekvent, men ikke steng opdragelse.</p>
                        <p>Den er ikke nogen udpræget beskytter, men er alligevel en pålidelig vagthund, der gør, men aldrig bider.</p>
                        <p>Den indordner sig nemt i familiemønstret.</p>
                        <p>Da den er en brugshund skal den have mulighed for at bruge sine evner.Ikke nødvendigvis ved at hyrde får, men gennem forskellige former for aktivering.</p>
                        <p>
                            Den Islanske Fårehund er en lille, stærk og robust bygget spidshund, som ikke lader sig hæmme af uvejsomt terræn eller dårligt vejr. Den har en stærk og spændstig krop og
                            den er let og adræt til fods. Pelsen er tyk og vandtæt og dens selvrensende evne gør, at en Islandsk Fårehund aldrig lugter af våd hund.
                        </p>
                    </article>
                </TextField>
            </main>
            <Footer />
        </body>
    );
}
