import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate, Link } from "react-router-dom";

export default function Home() {
    const wlp = window.location.pathname;
    const navigate = useNavigate();

    return (
        <body>
            <Header />
            <main>
                <div className="Errorpage">
                    <h2>
                        siden "<span>{wlp}</span>" blev ikke fundet
                    </h2>
                    <div>
                        <button onClick={() => navigate(-1)}>Gå tilbage til den sidste side</button>
                        <br />
                        <Link to="/">
                            <button>Gå til forsiden</button>
                        </Link>
                    </div>
                </div>
            </main>
            <Footer />
        </body>
    );
}
